//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import global from '~/mixins/global.js'
import * as scrollFunctions from '~/mixins/scroll.functions.js'
Vue.use(VueYoutube)
export default {
  mixins: [global],
  inheritAttrs: false,
  props: {
    data: {
      type: [String, Array, Object],
      default: () => ['', [], {}]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    dataAlign: {
      type: String,
      default: ''
    },
    zone: {
      type: String,
      default: ''
    }
  },
  data () {
    let dataParsed = this.data
    if (typeof dataParsed === 'string') {
      if (this.$isAMP) {
        dataParsed = dataParsed.replace(
          /<img/g,
          '<amp-img width="300" height="140"'
        )
        dataParsed = dataParsed.replace(/<iframe/g, '<amp-iframe')
        dataParsed = dataParsed.replace(/<amp-iframe (.*)width=".*?"/g, '<amp-iframe $1')
        dataParsed = dataParsed.replace(/<amp-iframe (.*)height=".*?"/g, '<amp-iframe $1height="200"')
      }
    }

    return {
      dataParsed,
      minus: { src: require('@/assets/img/icons/icon-minus-primary.svg') },
      plus: { src: require('@/assets/img/icons/icon-plus.svg') }
    }
  },
  mounted () {
    const links = this.$el.getElementsByTagName('a')
    links &&
      Object.values(links).forEach((link) => {
        const onClickArray = (link.onclick && [link.onclick]) || []

        if (
          link.attributes.href &&
          link.attributes['data-track'] &&
          link.attributes['data-track'].value
        ) {
          link.attributes.href.value = this.setDynamicShopLink(
            'enlace_wysiwyg',
            (link.attributes.name && link.attributes.name.value) || 'vacia',
            link.attributes['data-track'].value,
            '',
            '',
            '',
            false
          )
        }

        if (link.attributes.href && link.attributes.href.value.match(/^#/)) {
          onClickArray.push(function () {
            scrollFunctions.init()
            scrollFunctions.set(this.attributes.href.value)
            // document.querySelector(this.attributes.href.value) && document.querySelector(this.attributes.href.value).scrollIntoView()
            return false
          })
        }

        if (
          link.attributes.href &&
          link.attributes.href.value.match(/^\//) &&
          (!link.attributes.target || !link.attributes.target.value)
        ) {
          onClickArray.push(() => {
            this.$router.push(link.attributes.href.value)
            return false
          })
        }

        if (onClickArray) {
          link.onclick = (event) => {
            let onclickReturn = true
            onClickArray.forEach((onClickFunction) => {
              onclickReturn =
                onclickReturn && onClickFunction.apply(link, [event]) !== false
            })
            return onclickReturn
          }
        }
      })
  }
}
